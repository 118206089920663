<template>
    <div>
        <div class="banner">
                <img src="../assets/banner.png" alt="" srcset="">
        </div>
        <div class="main">
            <div class="title">
                订单详情
            </div>
            <div class="order">
                <div class="order_box">
                    <img src="../assets/big_order.png" alt="">
                    订单说明：{{order_detail.name}}
                </div>
                <div class="order_box" v-if="order_detail.type != 3">
                    订单号：{{order_detail.order_id}}
                </div>
            </div>
            <div class="title" style="margin-top:50px;">
                请选择支付方式
            </div>
            <div class="pay_box" v-if="is_vip">
                会员免支付
            </div>
            <div class="pay_box" v-else>
                <img src="../assets/alipay.png" alt="" @click="payOrder('alipay')">
                <img src="../assets/wx_pay.png" alt="" @click="payOrder('wx')">
            </div>
            
            <div class="money">
                <img src="../assets/blue_money.png" alt="">
                实付款：
                <span style="color:red;font-size:31px">￥</span>
                <span style="color:red;font-size:50px" >{{is_vip?'会员免支付':order_detail.money}}</span>
            </div>
            <div style="display:flex; justify-content: flex-end;">
                <div class="pay_button" @click="payOrder('balance')">
                    {{is_vip?'会员免支付':'去付款'}}
                </div>
            </div>

        </div>
       
            <div class="qrcode" ref="qrCodeUrl" style="display:flex; justify-content: center;"></div>
            <div class="wechat_text" v-if="show_code"> 请使用微信扫码支付</div>
            <div class="complete_order" v-if="show_code" @click="complete_pay" v-loading.fullscreen.lock="fullscreenLoading">完成支付</div>
        
    </div>
</template>
<script>
import * as api from '../config/api.js'
// import QR from '../config/wxqrcode'//gen未定义
import QRCode from 'qrcodejs2'
export default {
    data(){
        return{
            order_detail:'',
            is_vip:false,
            type:'',//3:会员支付 2:标书订单 1：咨询订单
            chat:false,
            mask:false,
            show_code:false,
            fullscreenLoading:false
        }
    },
    // watch:{
    //     mask(val){
    //         if(val){
    //             document.body.style.overflow = 'hidden'
    //         }else{
    //             document.body.style.overflow = ''
    //         }
    //     }
    // },
    created(){
        console.log(api.return_url)
       this.order_detail=JSON.parse(this.$route.query.order_detail)
       if(this.order_detail.chat){
           this.chat = true
       }
        if(this.$local.get('userinfo').grade>1){
				this.is_vip = true
        }
        this.type = this.order_detail.type
        if(this.type == 3){
            this.order_detail.order_id = 1
        }
        // switch (this.order_detail.type) {
		// 		case '1' :
		// 			this.type = 1
		// 			break;
		// 		case '3' :
		// 			this.type = 3wx
		// 			break;
        // }
    },
    methods:{
         creatQrCode(url) {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: url, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        complete_pay(){
            this.fullscreenLoading = true
            this.$api.userpost('order.details',{order_id:this.order_detail.order_id},(res)=>{
					if(res.data.status){
						switch(res.data.data.pay_status){
							case 1:
								this.fullscreenLoading = false
								this.$message.error('未支付')
								break;
							case 2:
								this.fullscreenLoading = false
								this.$message({messgae:'支付成功',type:'success'})
								setTimeout(()=>{
									if(this.type == 3){
										this.$router.push({path:'/mine'})
									}else{
                                        if(this.chat){
                                            setTimeout(()=>{
                                            this.$router.push({path:'/chat',query:{order_id:this.order_detail.order_id}})
                                            },1000)
                                        }else{
                                            setTimeout(()=>{
                                                this.$router.push({path:'/order'})
                                            },1000)
                                        }
									}
								},2000)
								break;
						}
					}else{
						this.fullscreenLoading = false
						this.$message.error(res.data.msg)
					}
				})
        },
        payOrder(item){
				let data ={
					ids:'',
					payment_code:'',
					payment_type:'',
					params:{}
				}
				switch (item) {
					// 余额支付
					case 'balance' :
						data.ids = this.order_detail.order_id,
						data.params.grade = this.order_detail.grade,
						data.params.return_url = '',
						data.params.money = this.order_detail.money,
						data.payment_code = 'balancepay',
						data.payment_type = this.type
						if(this.type != 3){
							data.ids = this.order_detail.order_id
						}
						this.$api.userpost('user.pay',data,(res)=>{
							if(res.data.status){
                                this.$message(res.data.msg)
								if(this.type == 3){
									setTimeout(()=>{
                                        this.$router.push({path:'/mine'})
									},2000)
								}else{
                                    console.log(this.chat)
									if(this.chat){
                                        setTimeout(()=>{
                                           this.$router.push({path:'/chat',query:{order_id:this.order_detail.order_id}})
										},2000)
									}else{
										setTimeout(()=>{
                                            this.$router.push({path:'/order'})
										},2000)
									}
								}
							}else{
								this.$message.error(res.data.msg)
							}
						})
					break;
					// 微信支付
					case 'wx':
							data.ids = this.order_detail.order_id,
							data.params.grade = this.order_detail.grade,
                            data.params.return_url = '',
                            data.params.trade_type = 'NATIVE',
							data.params.money = this.order_detail.money,
							data.payment_code = 'wechatpay',
							data.payment_type = this.type
						this.$api.userpost('user.pay',data,(res)=>{
                            // let img_url = QR.createQrCodeImg(res.data.data.code_url,{size:parseInt(300)})
                            if(res.data.status){
                                // this.mask = true
                                this.show_code = true
                                this.creatQrCode(res.data.data.code_url)
                            }else{
                                this.$message.error(res.data.msg)
                            }
						})
						// #ifdef MP-WEIXIN
							
						
						
						// #endif
						
						break;
					case 'alipay'://支付宝支付
					if(this.type == 3){
                        this.$local.set('pay_vip',true)
                        data.params.return_url =api.return_url + 'mine'
					}else if (this.type ==1){
						if(this.chat == 1){
                            this.$local.set('pay_chat',true)
                            data.params.return_url =api.return_url + 'chat'
						}else{
                            this.$local.set('pay_order',true)
                            data.params.return_url =api.return_url + 'order'
						}
					}
						data.ids = this.order_detail.order_id,
                        data.params.grade = this.order_detail.grade,
						// data.params.return_url =api.return_url + 'result',
						data.params.money = this.order_detail.money,
						data.params.trade_type = 'PC'
						data.payment_code = 'alipay',
						data.payment_type = this.type
						this.$api.userpost('user.pay',data,(res)=>{
							if(res.data.status){
								const url = res.data.data.url
								const data = res.data.data.data
															
								// 模拟GET提交
								let tempForm = document.createElement('form')
								tempForm.id = 'aliPay'
								tempForm.methods = 'post'
								tempForm.action = url
								tempForm.target = '_self'
								let input = []
								for (let k in data) {
									input[k] = document.createElement('input')
									input[k].type = 'hidden'
									input[k].name = k
									input[k].value = data[k]
									tempForm.appendChild(input[k])
								}
								tempForm.addEventListener('submit', function() {}, false)
								document.body.appendChild(tempForm)
								tempForm.dispatchEvent(new Event('submit'))
								tempForm.submit()
								document.body.removeChild(tempForm)
							}else{
								this.$message.error(res.data.msg)
							}
						})
					break;
                    
                   
					
				}
			}




    }
    
}
</script>
<style lang="scss" scoped>
.mask{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
  }
.banner{
        margin: 0 auto;
        margin-top: 45px;
        height: 196px;
        width: 1200px;
        img{
            height: 196px;
         width: 1200px;
        }
}
    .order{
        margin: 0 auto;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .order_box{
            padding-left: 25px;
            width: 530px;
            height: 80px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 22px;
            color: #666;
            img{
                height: 33px;
                width: 28px;
                margin-right: 10px;
            }
        }
    }
   
    .pay_box{
        display: flex;
        margin-top: 25px;
        img{
            height: 110px;
            width: 300px;
        }
        img:nth-child(2){
            margin-left: 26px ;
        }
    }
    
    .money{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 30px;
        img{
            height: 33px;
            width: 33px;
            margin-right:10px;
        }
    }

    .pay_button{
        width: 278px;
        height: 60px;
        background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
        opacity: 1;
        border-radius: 10px;
        line-height: 60px;
        text-align: center;
        font-size: 22px;
        color: #fff;
        margin-top: 30px;
    }

    
    .wechat_text{
        color: #09BB07;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
    }
    .complete_order{
            margin: 20px auto;
             height: 40px;
            line-height: 40px;
            background-color: #3C7DE2;
            color: #FFF;
            font-size: 20px;
            text-align: center;
            border-radius: 20px;
            width: 200px;
    }
</style>